<template>
  <div class="flex flex-col min-h-dvh bg-gradient-to-b from-primary to-secondary text-white">
    <Header />
    <div class="flex flex-col flex-grow mx-auto w-full max-w-7xl p-2 lg:px-8 mb-4">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts" setup></script>
