<i18n lang="json">{
    "en": {
        "augwa": "Augwa",
        "contactUs": "Contact us"
    }
}</i18n>

<template>
    <header>
        <div class="flex mx-auto max-w-72 py-8 items-center">
            <IconLogoAugwa class="fill-white size-full" />
        </div>
    </header>
</template>

<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
</script>